import { graphql, PageProps } from 'gatsby';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { NarrowCenteredContainer, TeamMemberPanel } from '../components';
import { parseTeamMembers } from '../helpers';
import { bgBannerLarge } from '../images';

const Team = ({ data }: PageProps) => {
  const { t } = useTranslation();
  const nodes = (data as any).allContentfulTeamMember.edges;
  const members = useMemo(
    () => parseTeamMembers(nodes.map((n: any) => n.node)),
    [nodes]
  );

  return (
    <>
      <Helmet>
        <title>{t('team.page-title')}</title>
      </Helmet>
      <section className="text-white bg-cover relative" id="banner">
        <div
          className="h-[calc(100%+var(--full-menu-height))] w-full absolute -z-50 top-[calc(-1*var(--full-menu-height))]"
          style={{ backgroundImage: `url("${bgBannerLarge}")` }}
        />
        <NarrowCenteredContainer className="py-28 text-center">
          <header className="text-primary font-secondary font-black uppercase text-xs md:text-2xl mb-4 md:mb-8">
            {t('team.banner.text-1')}
          </header>
          <p className="font-secondary font-black md:uppercase text-[30px] md:text-6xl leading-tight mb-4 md:mb-11">
            {t('team.banner.text-2')}
          </p>
          <p className="md:text-[2.25rem] leading-snug">
            {t('team.banner.text-3')}
          </p>
        </NarrowCenteredContainer>
      </section>
      <section className="padded-section bg-[color:rgba(var(--color-footer-text),.4)]">
        <NarrowCenteredContainer>
          <div
            className={`grid grid-cols-2 md:grid-cols-[repeat(auto-fit,minmax(240px,340px))] gap-y-8 md:gap-y-20 
          overflow-hidden md:overflow-visible items-start justify-items-center justify-center`}
          >
            {members.map((member) => (
              <TeamMemberPanel key={member.id} member={member} />
            ))}
            <Link className="block w-full md:w-[unset]" to="/careers">
              <div
                className={`bg-primary w-full md:w-[240px] aspect-square rounded-full 
                p-0 md:pt-16 md:px-12 flex md:block justify-center items-center flex-col`}
              >
                <span className="font-secondary font-semibold text-[5vw] md:text-2xl">
                  <Trans t={t} i18nKey="team.become-team-member" />
                </span>
                <span className="material-symbols-outlined text-[12vw] md:!text-6xl -ml-3">
                  arrow_outward
                </span>
              </div>
            </Link>
          </div>
        </NarrowCenteredContainer>
      </section>
    </>
  );
};

export default Team;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulTeamMember {
      edges {
        node {
          id
          firstName
          photo {
            publicUrl
          }
          position
          bio {
            bio
          }
        }
      }
    }
  }
`;
