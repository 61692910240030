import { TeamMember } from '../../models';

export function parseTeamMember(node: any): TeamMember {
  return {
    id: node.id,
    firstName: node.firstName,
    bio: node.bio.bio,
    photoUrl: node.photo.publicUrl,
    position: node.position[0],
  };
}

export function parseTeamMembers(nodes: any[]): TeamMember[] {
  return nodes.map(parseTeamMember);
}
